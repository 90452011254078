<template>
  <div class="wscn-http404-container">
    <img class="pic-404__child left" src="@/assets/img/stateImg/OffShelf.png" alt="404" />
    <div class="tips">店铺服务已到期</div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
.wscn-http404-container {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;
}
.tips {
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 44px;
  color: #5d5d5d;

  margin-top: 20px;
}
</style>
